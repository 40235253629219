
//== Font

$font-family: 'DunkinSans-Normal';
$font-family-body: 'DunkinSans-Normal';
$font-family-title: 'DunkinSans-Bold';
$font-family-bold: 'DunkinSans-Bold';
$font-size: 16;
$font-height: 20px;

// Base Colors
$colors-black: #000000;
$colors-white: #ffffff;
$colors-orange: #EF6A00;
$colors-pink: #C63663;
$colors-brown: #4E2C1D;
$colors-gray: #fafafa;
$colors-gray-2: #F1F1F1;
$colors-gray-3: #D4D4D4;
$colors-gray-4: #9B9B9B;
$colors-gray-5: #6E6E6E;
$colors-gray-6: #505050;
$colors-blue: #005FCC;

//== Color
$color-background-black: $colors-black;
$color-background-white: $colors-white;
$color-background-orange: $colors-orange;
$color-background-pink: $colors-pink;
$color-background-brown: $colors-brown;
$color-background-gray: $colors-gray;
$color-background-gray-2: $colors-gray-2;
$color-background-gray-3: $colors-gray-3;
$color-background-gray-6: $colors-gray-6;
$color-outline-blue: $colors-blue;
$color-border-black: $colors-black;
$color-border-white: $colors-white;
$color-border-pink: $colors-pink;
$color-border-brown: $colors-brown;
$color-border-white: $colors-white;
$color-border-orange: $colors-orange;
$color-border-gray: $colors-gray;
$color-border-gray-3: $colors-gray-3;
$color-border-gray-4: $colors-gray-4;
$color-border-gray-5: $colors-gray-5;
$color-border-gray-6: $colors-gray-6;
$color-text-black: $colors-black;
$color-text-white: $colors-white;
$color-text-pink: $colors-pink;
$color-text-gray: $colors-gray;
$color-text-orange: $colors-orange;
$color-text-gray-3: $colors-gray-3;
$color-text-gray-5: $colors-gray-5;
$color-text-gray-6: $colors-gray-6;


// Breakpoints
$breakpoint-tablet: 769px; // 768 + 1 so that 768 is still tablet
$breakpoint-mobile: 577px;

$grid-breakpoints: (
  xs: 0,
  sm: $breakpoint-mobile,
  mobile: $breakpoint-mobile,
  md: $breakpoint-tablet,
  tablet: $breakpoint-tablet,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

$spacing-base: 8;
