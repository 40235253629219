.footer {
  background: $color-background-gray-2;
  padding: spacing(9) spacing(20);

  .cmp-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }

  @include breakpoint-down(tablet) {
    padding-left: spacing(7);
    padding-right: spacing(7);
  }

  &__copyright-text {
    width: 100%;
    font-family: TCCC-UnityText-Regular;
    color: $color-text-black;
    font-size: rem-calc(12);
    justify-content: flex-end;
    padding-top: spacing(3);
    border-top: rem-calc(1) solid $color-border-gray-4;
    @include breakpoint-down(lg) {
      padding-top: 0;
      border-top: none;
    }
    p {
      line-height: rem-calc(16);
      text-align: right;
      color: $color-text-black;
      @include breakpoint-down(lg) {
        text-align: left;
      }
    }
  }

  &__image {
    width: 100%;
    border-bottom: rem-calc(1) solid $color-border-gray-4;
    img {
      width: spacing(25);
      margin-bottom: spacing(6);
    }
  }

  &__social-icons {
    margin-top: spacing(4.5);

    @include breakpoint-down(lg) {
      width: 100%;
      margin-top: 0;
      padding-top: spacing(2);
      padding-bottom: 0;
      border-top: rem-calc(1) solid $color-border-gray-4;
    }
  }

  &__links {
    margin-top: spacing(3);
    width: 65%;
    text-transform: uppercase;
    @include breakpoint-down(tablet) {
      width: 100%;
    }
    @include breakpoint-down(lg) {
      margin-top: spacing(2);
    }
    .cmp-text {
      a {
        text-decoration: none;
        color: $color-text-black;
        &:hover {
          color: $color-border-pink;
        }
      }
      @include breakpoint-down(tablet) {
        padding-bottom: 0;
        margin-bottom: spacing(2);
      }

      ul {
        padding: 0;
        margin: spacing(2) 0 0 0;
        display: flex;
        flex-direction: row;
        list-style: none;
        @include breakpoint-down(tablet) {
          flex-direction: column;
        }
        li {
          position: relative;
          margin-right: spacing(2.5);
          margin-bottom: spacing(2);
          color: $color-text-gray-5;
          width: spacing(17);
          font-weight: 800;
          font-size: rem-calc(12);
          line-height: rem-calc(30);
          @include breakpoint-down(tablet) {
            color: $color-text-black;
            cursor: pointer;
          }
          a {
            color: $color-text-black;
          }
          ul {
            padding: 0;
            a {
              font-size: rem-calc(16);
              font-weight: bold;
              color: $color-text-black;
            }
          }
          @include breakpoint-down(tablet) {
            font-weight: 600;
            font-size: rem-calc(20);
            width: 100%;

            &.has-li {
              &.opened {
                &::after {
                  transform: rotate(225deg);
                }
                ul {
                  display: none;
                }
              }

              &::after {
                content: '';
                position: absolute;
                top: rem-calc(5);
                right: rem-calc(5);
                width: 0;
                height: 0;
                width: rem-calc(15);
                height: rem-calc(15);
                border-top: rem-calc(2) solid $color-border-pink;
                border-left: rem-calc(2) solid $color-border-pink;
                transform: rotate(225deg);
                transition: transform 0.3s ease;
              }

              &.opened {
                &::after {
                  transform: rotate(45deg);
                }
                ul {
                  display: block;
                }
              }
            }
          }

          ul {
            flex-direction: column;
            li {
              font-weight: 400;
              font-size: rem-calc(14);
              &::after {
                display: none;
              }
            }
            @include breakpoint-down(tablet) {
              display: none;
            }
          }
        }
      }
    }
  }

  .container.social-icons .cmp-container {
    gap: 0;
    .button {
      margin-right: spacing(2);
      &:last-of-type {
        margin-right: 0;
      }
      .cmp-button__icon {
        width: rem-calc(32);
        height: rem-calc(32);
        &:after {
          width: rem-calc(32);
          height: rem-calc(32);
        }
      }
    }

    margin-right: 0;
    justify-content: flex-start;
    @include breakpoint-down(lg) {
      margin-left: 0;
      margin-top: spacing(2);
      margin-bottom: spacing(2);
    }
  }
}
