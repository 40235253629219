.container.social-icons {
  .cmp-container {
    display: flex;
    gap: rem-calc(24);

    .cmp-button {
      background-color: transparent;
      border-radius: 100%;
      border: rem-calc(2) solid $color-border-black;
      color: $color-text-black;
      padding: 0;
      width: rem-calc(40);
      height: rem-calc(40);
      min-height: auto;
      min-width: auto;

      &__icon {
        height: rem-calc(18);
        width: rem-calc(18);
        font-size: rem-calc(18);

        &:after, &:before {
          height: rem-calc(18);
          width: rem-calc(18);
          font-size: rem-calc(18);
        }
      }

      &__text {
        @include sr-only();
      }

      &:hover, 
      &:focus {
        background-color: $color-background-black;
        color: $color-text-white;
      }
    }
  }

  &--orange {
    .cmp-container {
      .cmp-button {
        border-color: $color-border-orange;
        color: $color-text-orange;

        &:hover, 
        &:focus {
          background-color: $color-background-orange;
          color: $color-text-white;
        }
      }
    }
  }

  &--white {
    .cmp-container {
      .cmp-button {
        border-color: $color-border-white;
        color: $color-text-white;

        &:hover, 
        &:focus {
          background-color: $color-background-white;
          color: inherit;
        }
      }
    }
  }

  &--small {
    .cmp-container {
      gap: spacing(2);

      .cmp-button {
        width: rem-calc(32);
        height: rem-calc(32);

        &__icon {
          width: rem-calc(16);
          height: rem-calc(16);
          font-size: rem-calc(16);

          &:after, &:before {
            width: rem-calc(16);
            height: rem-calc(16);
            font-size: rem-calc(16);
          }
        }
      }
    }
  }
}