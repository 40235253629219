.teaser.full-bleed {
  .cmp-teaser {
    display: flex;
    align-items: center;
    gap: spacing(4);

    @include breakpoint-down(tablet) {
      flex-flow: column-reverse;
    }

    > * {
      flex-basis: 0;
      flex-grow: 1;
    }
    
    &__pretitle, 
    &__title,
    &__description {
      @include breakpoint-down(tablet) {
        text-align: center;
      }
    }

    &__pretitle {
      font-size: rem-calc(12);
      line-height: rem-calc(14);
      letter-spacing: rem-calc(0.5);
      text-transform: uppercase;
      font-family: 'DunkinSans-Medium';
      font-weight: 800;
      margin: 0 0 spacing(2);
    }

    &__title {
      font-size: rem-calc(40);
      font-family: 'DunkinSans-Bold';
      color: $color-text-black;
      line-height: normal; 
      text-transform: uppercase;
      margin: 0 0 spacing(2);

      @include breakpoint-down(tablet){
        font-size: rem-calc(36);
      }

      a {
        color: $color-text-black;
        text-decoration: none;
      }
    }

    &__description {
      font-size: rem-calc(16);
      line-height: 150%;
    }

    &__action-link {
      margin-top: spacing(4);

      @include breakpoint-down(tablet) {
        width: 100%;
      }
    }
  }

  &.text-left {
    .cmp-teaser {
      &__content {
        padding-right: spacing(12); 

        @include breakpoint-down(tablet) {
          padding-right: 0;
        }
      }
    }
  }

  &.text-right {
    .cmp-teaser {
      flex-direction: row-reverse;

      @include breakpoint-down(tablet) {
        flex-direction: column-reverse;
      }

      &__content {
        padding-left: spacing(12);
        
        @include breakpoint-down(tablet) {
          padding-left: 0;
        }
      }
    }
  }

  &.button--tertiary {
    @include breakpoint-down(tablet) {
      .cmp-teaser {
        &__action-container {
          text-align: center;
        }
        &__action-link {
          width: auto;
        }
      }
    }
  }
}