.container {
  .cmp-container {
    overflow-x: visible;
  }
  &.centered-content {
    width: auto;
    padding: 0 spacing(3);

    .cmp-container {
      max-width: rem-calc(1120);
      margin: 0 auto;

      @include breakpoint-down(tablet) {
        max-width: rem-calc(640);
      }
    }
  }
}
