.button {
  $this: &;

  .cmp-button {
    @include base-button();
  }
  
  &--secondary {
    .cmp-button {
      @include secondary-button();
    }
  }

  &--tertiary {
    .cmp-button {
      @include tertiary-button();
    }
  }
  
  &--inverted {
    &#{$this}--secondary {
      .cmp-button {
        @include secondary-button(true);
       }
    }
  }
}
