//== Font loading

@mixin fontface($name, $file, $weight: normal, $style: normal, $type: ttf) {
  @font-face {
    font-weight: $weight;
    font-family: '#{$name}';
    font-style: $style;
    src: url('resources/fonts/#{$file}.#{$type}') format('truetype');
  }
}

// Mixin for system font (Arial)
@mixin bodyFont($weight: normal, $style: normal) {
  font-family: $font-family-body;
  font-weight: $weight;
  font-style: $style;
}

// Mixin for system font variations
@mixin bodyFontVariation($weight: normal, $style: normal) {
  font-family: $font-family-body;
  font-weight: $weight;
  font-style: $style;
}

@font-face {
  font-family: 'icomoon';
  src: url('./resources/fonts/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'DunkinSans-Normal';
  src: url('./resources/fonts/DunkinSans-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DunkinSans-Medium';
  src: url('./resources/fonts/DunkinSans-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DunkinSans-Bold';
  src: url('./resources/fonts/DunkinSans-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TCCC-UnityText-Regular';
  src: url('./resources/fonts/TCCC-UnityText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}