.container.product-information {
  .cmp-container {
    margin-bottom: rem-calc(32);
    
    @include breakpoint-up(tablet) {
      display: flex;
      gap: rem-calc(32);
    }

    > * {
      flex-grow: 1;
      flex-basis: 0;
    }

    .adaptiveImage {
      img {
        box-shadow: 0 rem-calc(1) rem-calc(8) rgba(0, 0, 0, 0.06);
      }
    }

    .product-information-content {
      @include breakpoint-up(tablet) {
        align-self: center;
        padding-left: rem-calc(96);
      }

      .cmp-container {
        text-align: center;
        display: block;
  
        @include breakpoint-up(tablet) {
          text-align: initial;
        }
      }
    }
  }

  &.reversed-content {
    .cmp-container {
      @include breakpoint-up(tablet) {
        flex-direction: row-reverse;
      }

      .product-information-content {
        @include breakpoint-up(tablet) {
          padding-left: 0;
          padding-right: rem-calc(96);
        }  
      }
    }
  }
}