.container.banner-bar {
  > .cmp-container {
    display: flex;
    align-items: center;
    background-color: $color-background-orange;
    color: $color-background-orange; // Setting the text color to orange so that the social icon hover can take the background color
    border-radius: rem-calc(8);
    padding: spacing(6) spacing(12);
    gap: spacing(7);
    box-sizing: border-box;

    @include breakpoint-down(tablet) {
      flex-flow: column;
      gap: spacing(4);
      text-align: center;
      padding: spacing(5) spacing(3);
    }

    > :first-child {
      flex-grow: 1;
    }

    .text, .title {
      color: $color-text-white;
    }
    
    .text {
      line-height: normal;
      
      h1, h2, h3, h4, h5, h6 {
        text-transform: uppercase;
        font-family: 'DunkinSans-Bold';
        font-size: rem-calc(30);

        @include breakpoint-down(tablet) {
          font-size: rem-calc(28);
        }
      }
    }

    > .button {
      @include breakpoint-down(tablet) {
        width: 100%;
      }

      .cmp-button {
        min-width: rem-calc(327);
        
        @include breakpoint-down(tablet) {
          min-width: auto;
          width: 100%;
        }
      }
    }
  }
}