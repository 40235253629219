.accordion.nutritional-information {
  padding: 0;
  font-family: $font-family-bold;
  .cmp-accordion {
    margin: 0;
    &__button {
      display: flex;
      padding: 0;
      border: none;
      background-color: $color-background-white;
    }
    &__title {
      font-size: rem-calc(16);
      font-weight: 800;
      color: $color-text-black;
    }
    &__icon {
      margin-top: spacing(.5);
      font-size: rem-calc(9);
      padding: 0 spacing(1);
    }
    &__panel {
      padding: 0;
    }
  }
}

.accordion.faq {
  .cmp-accordion {
    margin: 0;
    &__button {
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: space-between;
      height: rem-calc(90);
      border: rem-calc(1) solid transparent;
      background-color: transparent;
      &--expanded {
        .cmp-accordion__icon {
          @include icon($iconCaretUp, 10, 10);
        }
      }
    }
    &__item {
      border-bottom: 1px solid $color-border-gray-5;
    }
    &__title {
      font-family: 'TCCC-UnityText-Regular';
      font-size: rem-calc(18);
      font-weight: 600;
      color: $color-text-black;
    }
    &__panel {
      padding: 0;
    }
    &__icon {
      @include icon($iconCaretDown, 10, 10);
      margin-top: rem-calc(-2);
      font-size: rem-calc(9);
      padding: 0 spacing(1);
    }

    .cmp-text:first-of-type {
      margin-top: 0;
      p:first-of-type {
        margin-top: 0;
      }
    }
  }
}

.cmp-accordion {
  margin: spacing(1);

  &__button {
    padding: spacing(0.5) spacing(1);
    background-color: $color-background-white;
    border: rem-calc(1) solid transparent;
    border-bottom-color: $color-border-gray-5;
    outline: none;
    cursor: pointer;
    .cmp-accordion__icon {
      @include icon($iconCaretDown, 14, 14);
    }
    &:focus-visible {
      outline: rem-calc(2) solid $color-outline-blue;
      border-radius: rem-calc(5);
      outline-offset: rem-calc(-2);
      .cmp-accordion__title {
        color: $color-text-black;
      }
    }
    &--expanded {
      outline: none;
      .cmp-accordion__icon {
        @include icon($iconCaretUp, 14, 14);
      }
      &:focus {
        border: rem-calc(2) solid transparent;
        border-bottom-color: $color-border-gray-5;
        border-radius: rem-calc(5);
        .cmp-accordion__title {
          color: $color-text-black;
        }
      }
    }
  }
  &__title {
    color: $color-border-black;
    font-weight: 300;
    font-size: rem-calc(16);
  }

  &__icon {
    float: right;
  }

  &__panel {
    padding: spacing(1) spacing(2);
  }
}
