// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);

    @return if($min  !=0, $min, null);
}

  // Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);

  @return if($max and $max > 0, $max - 0.02, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media screen and (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media screen and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// This is the primary button
@mixin base-button() {
  font-family: 'DunkinSans-Medium';
  border: none;
  cursor: pointer;
  background-color: transparent;
  box-sizing: border-box;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: rem-calc(40);
  padding: 0 spacing(3);
  background-color: $color-background-pink;
  height: rem-calc(40);
  font-size: rem-calc(16);
  line-height: 150%;
  font-weight: 800;
  text-transform: uppercase;
  color: $color-text-white;
  text-decoration: none;
  min-width: spacing(8);
  max-width: spacing(140);
  &:hover,
  &:focus {
    background-color: $color-background-brown;
  }
  &:focus {
    outline: rem-calc(2) solid $color-border-brown;
    outline-offset: rem-calc(2);
  }
  &.disabled {
    background-color: $color-background-gray-3;
    color: $color-text-gray-6;
    border-color: $color-border-gray-3;
    cursor: not-allowed;
  }
}

@mixin secondary-button($inverted: false) {
  @include base-button();

  line-height: rem-calc(14);
  font-weight: 500;

  border: rem-calc(2) solid $color-border-black;
  background-color: $color-background-white;
  color: $color-text-black;

  &:hover,
  &:focus {
    border-color: $color-border-brown;
    color: $color-text-white;
  }

  &.disabled {
    background-color: $color-background-white;
    color: $color-text-gray-3;
    cursor: not-allowed;
  }

  @if($inverted) {
    background-color: transparent;
    border-color: $color-border-white;
    color: $color-text-white;

    &:hover, 
    &:focus {
      background-color: transparent;
      border-color: $color-border-gray-3;
      color: $color-text-gray-3;
      outline-color: $color-border-white;
    }

    &:disabled {
      border-color: $color-border-gray-6;
      color: $color-text-gray-6;
    }
  }
}

@mixin tertiary-button() {
  background-color: transparent;
  color: $color-text-black;
  padding: 0;
  font-size: rem-calc(16);
  line-height: rem-calc(14);
  height: auto;
  @include icon($iconArrowRight);
  gap: spacing(1);
  
  &:hover,
  &:focus,
  &:disabled,
  &.disabled  {
    background-color: transparent;
  }

  &:hover {
    color: $color-text-gray-5;
  }

  &:focus {
    outline-offset: spacing(1);
    color: $color-text-black;
  }

  &:disabled, &.disabled {
    color: $color-text-gray-3;
  }
}

@mixin sr-only() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@mixin headerStyles() {
    h1, h2, h3, h4 {
        font-family: $font-family-title;
        letter-spacing: 0;
        line-height: 110%;
        text-transform: uppercase;
    }

    h1 {
        font-size: rem-calc(48);

        @include breakpoint-down(tablet) {
            font-size: rem-calc(42);
        }
    }

    h2 {
        font-size: rem-calc(40);

        @include breakpoint-down(tablet) {
            font-size: rem-calc(36);
        }
    }

    h3 {
        font-size: rem-calc(30);

        @include breakpoint-down(tablet) {
            font-size: rem-calc(28)
        }
    }

    h4 {
        font-size: rem-calc(24);

        @include breakpoint-down(tablet) {
            font-size: rem-calc(20);
        }
    }
}