.cmp-text {
  margin: 0;
  @include bodyFont();
  @include headerStyles();

  & p {
    line-height: rem-calc(24);
  }
  & b {
    font-weight: bold;
  }
  & p a {
    color: $color-text-black;
    border: rem-calc(1) solid transparent;
    border-radius: rem-calc(4);
    &:hover {
      color: $color-text-pink;
      text-decoration: underline;
      text-decoration-thickness: rem-calc(1);
      text-decoration-color: $color-text-pink;
      text-decoration-style: solid;
    }
  }
}