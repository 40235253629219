.cmp-image {
  img {
    height: fit-content;
  }
}
.cmp-image__link {
  display: block;
  width: fit-content;
}
.cmp-image__image {
  display: block;
}
.cmp-image__title {
}
