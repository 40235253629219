.title {
  &.text-center {
    .cmp-title__text {
      text-align: center;
    }
  }
  &.text-right {
    .cmp-title__text {
      text-align: right;
    }
  }

  .cmp-title {
    @include headerStyles();
    margin-top: spacing(5);
    margin-bottom: spacing(1.5);
  }
}
