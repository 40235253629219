.cmp-embed__pricespider {
    .ps-widget {
      @include breakpoint-down(tablet) {
        width: 100%;
      }
    }
}
.cmp-embed {
  position: relative;
  iframe {
    top: 0;
    left: 0;
    &:not([width]) {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}