//== Functions
@import 'site/variables';
@import 'site/mixins';
@import 'site/functions';
@import 'site/icons';
@import 'site/grid';

@import 'npm:normalize.css';
@import 'site/fonts';
@import 'site/base';



//== Core components
@import './components/accordion/accordion';
@import './components/button/button';
@import './components/embed/embed';
@import './components/embed/_embed-third-party-experience.scss';
@import './components/container/container';
@import './components/container/container_banner_bar';
@import './components/container/container_product_information';
@import './components/teaser/teaser';
@import './components/teaser/teaser_full_bleed';
@import './components/container/container_social_icons';
@import './components/separator/separator';

// commenting these out until we need them

@import 'components/text/_text.scss';

@import 'components/title/_title.scss';
// @import 'components/title/_title_styles.scss';

@import 'components/image/_image.scss';

// @import 'components/image/_image_caption.scss';
// @import 'components/image/_image_standard.scss';

//== Header Component

@import 'components/header/_header.scss';

//== Footer Component

@import 'components/footer/_footer.scss';

//== Nurtritional Information
@import 'components/nutritionalinformation/_nutritionalinformation.scss';

