.teaser {
  .cmp-teaser {
    &__action-link {
      @include base-button();
    }
  }

  &.button--secondary {
    .cmp-teaser__action-link {
      @include secondary-button();
    }
  }

  &.button--tertiary {
    .cmp-teaser__action-link {
      @include tertiary-button();
    }
  }
}